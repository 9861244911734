/* eslint-disable @next/next/no-img-element */
import { Box, Typography, Stack, useTheme, styled, Button } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useAppSettingsContext } from '@/app/components/contexts/app-settings-context';
import InViewAnimationComponent from '@/app/components/in-view-animation-container';
import { APP_NAME } from '@/app/utilities/consts';

const NextImage = styled(Image)`
  position: relative !important;
  object-fit: contain;
  border-radius: '0.5rem';
`;

const storeData = [
  {
    image: '/assets/apps/store/google_play.png',
    link: 'https://play.google.com/store/apps/details?id=smart.player.max',
    alt: 'android',
  },
  // {
  //   image: '/assets/apps/store/ios.png',
  //   link: '/coming-soon',
  //   alt: 'apple',
  // },
  // {
  //   image: '/assets/apps/store/lg.png',
  //   link: '/coming-soon',
  //   alt: 'lg',
  // },
  {
    image: '/assets/apps/store/roku.png',
    link: 'https://channelstore.roku.com/details/10956562b2262f74e05a9af2638225a8/smart-player-max',
    alt: 'roku',
  },
  // {
  //   image: '/assets/apps/store/samsung.png',
  //   link: '/coming-soon',
  //   alt: 'samsung',
  // },
  {
    image: '/assets/apps/store/windows.png',
    link: 'https://www.microsoft.com/store/apps/9NSFKGT9C2TZ',
    alt: 'windows',
  },
  // {
  //   image: '/assets/apps/store/web.png',
  //   link: '/coming-soon',
  //   alt: 'web',
  // },
];

// {
//   image: '/assets/apps/direct/android.png',
//   description: 'Download the apk file for Android, Fire TV (AFT downloader code is 468423) :',
//   link: '/node-static/app-website-release.apk',
//   alt: 'android',
// },

const downloadData = [
  // {
  //   image: '/assets/apps/direct/samsung.png',
  //   description: 'Download the widget file for Samsung TV :',
  //   link: '/coming-soon',
  //   alt: 'samsung',
  // },
  // {
  //   image: '/assets/apps/direct/exe.png',
  //   description: 'Download exe file for Windows Desktop :',
  //   link: 'https://api.streamingtechllc.com/node-static/Smarters%20Max%20IPTV%20v1.0.6.0.exe',
  //   alt: 'windows',
  // },
];

export default function DownloadApps() {
  const theme = useTheme();

  const { settings } = useAppSettingsContext();
  const androidSetting = Array.isArray(settings)
    ? settings.find(item => item.key === `android-setting-${APP_NAME}`)
    : undefined;

  const windowsSetting = Array.isArray(settings)
    ? settings.find(item => item.key === 'windows-setting')
    : undefined;

  return (
    <Box
      sx={{
        mx: 'auto',
        width: {
          md: '100%',
          lg: '1200px',
        },
        pt: 15,
        pb: 15,
      }}
      id="download-apps"
    >
      <Stack gap={6}>
        <Stack alignItems="center" gap={2} paddingX={3}>
          <Typography variant="h3">Download Smart Max IPTV</Typography>
          <Typography
            variant="h6"
            sx={{
              maxWidth: 600,
              textAlign: 'center',
            }}
            color={theme.palette.text.secondary}
          >
            Experience seamless streaming with Smart Max IPTV—your ultimate destination for
            downloading and enjoying high-quality videos
          </Typography>
        </Stack>
        <Stack paddingX={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexFlow: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
            }}
          >
            {storeData.map((item, index) => (
              <Link key={index} href={item.link} target="_blank">
                <InViewAnimationComponent>
                  <Box
                    sx={{
                      height: 60,
                      '& img': {
                        height: '60px',
                        objectFit: 'contain',
                        borderRadius: '8px',
                      },
                    }}
                  >
                    <img src={item.image} alt={item.alt} />
                  </Box>
                </InViewAnimationComponent>
              </Link>
            ))}
          </Box>
        </Stack>

        <Stack paddingX={3}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 3,
            }}
          >
            {androidSetting &&
              androidSetting?.value &&
              androidSetting.value?.path &&
              androidSetting.value?.code && (
                <InViewAnimationComponent>
                  <Stack gap={1}>
                    <Typography variant="subtitle1" color={theme.palette.text.secondary}>
                      {`Download the apk file for Android, Fire TV (AFT downloader code is ${androidSetting.value?.code}) :`}
                    </Typography>
                    <Button
                      onClick={() => {
                        const uniqueSuffix = new Date().getTime();

                        window.location.href = `${androidSetting.value?.path}?v=${uniqueSuffix}`;
                      }}
                    >
                      <Box
                        sx={{
                          height: 60,
                          '& img': {
                            height: '60px',
                            objectFit: 'contain',
                            borderRadius: '8px',
                          },
                        }}
                      >
                        <img src="/assets/apps/direct/android.png" alt="" />
                      </Box>
                    </Button>
                  </Stack>
                </InViewAnimationComponent>
              )}

            {windowsSetting && windowsSetting?.value && windowsSetting.value?.path && (
              <InViewAnimationComponent>
                <Stack gap={1}>
                  <Typography variant="subtitle1" color={theme.palette.text.secondary}>
                    Download exe file for Windows Desktop :
                  </Typography>
                  <Button
                    onClick={() => {
                      const uniqueSuffix = new Date().getTime();

                      window.location.href = `${windowsSetting.value?.path}?v=${uniqueSuffix}`;
                    }}
                  >
                    <Box
                      sx={{
                        height: 60,
                        '& img': {
                          height: '60px',
                          objectFit: 'contain',
                          borderRadius: '8px',
                        },
                      }}
                    >
                      <img src="/assets/apps/direct/exe.png" alt="" />
                    </Box>
                  </Button>
                </Stack>
              </InViewAnimationComponent>
            )}

            {downloadData.map((item, index) => {
              const uniqueSuffix = new Date().getTime();
              return (
                <InViewAnimationComponent key={index}>
                  <Stack gap={1}>
                    <Typography variant="subtitle1" color={theme.palette.text.secondary}>
                      {item.description}
                    </Typography>
                    <Button
                      // href={item.link}
                      onClick={() => {
                        window.location.href = `${item.link}?v=${uniqueSuffix}`;
                      }}
                    >
                      <Box sx={{ height: 60 }}>
                        <NextImage sx={{ borderRadius: 1 }} src={item.image} fill alt={item.alt} />
                      </Box>
                    </Button>
                  </Stack>
                </InViewAnimationComponent>
              );
            })}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}

/* <Typography variant="subtitle1">{data.title}</Typography>
  <Typography variant="body2">{data.description}</Typography> */
